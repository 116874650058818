// =============================================================================
// app - javascript - controllers - import progress controller
// =============================================================================
import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

export default class extends Controller {
  static targets = ['progressData', 'progressBar'];
  static values = {
    statusPath: String,
    jobId: String,
    progress: Number,
  };

  statusPathValue: string;
  jobIdValue: string;
  progressValue: number;
  count: number;
  timerId: number;

  progressDataTarget: HTMLElement;
  progressBarTarget: HTMLProgressElement;

  async connect() {
    this.count = 0;
    this.timerId = window.setInterval(async () => {
      const query = new URLSearchParams({ job_id: this.jobIdValue });
      const response = await fetch(this.statusPathValue.concat('?', query.toString()));
      const message = await response.text();

      Turbo.renderStreamMessage(message);

      // renderStreamMessage で DOM の書き換えが完了するまで遅延させる
      setTimeout(() => {
        const { status, percentage } = this.progressDataTarget.dataset;

        this.count += 1;

        this.progressBarTarget.value = Number(percentage);

        if (status === 'queued' || status === 'working') {
          // do nothing
        } else {
          clearInterval(this.timerId);
        }
      }, 100);
    }, 1000);
  }

  disconnect() {
    clearInterval(this.timerId);
  }
}
